import logo from './logo.svg';
import './App.css';
import React, { useState,useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, Link, Routes,useNavigate,Navigate } from 'react-router-dom';
import Home from "./pages/Home";
import Accueil from "./pages/accueil/accueil";
import Choix from "./pages/choix/choix";
import Merci from "./pages/merci/merci";

import Admin from "./pages/admin/admin";
import BackofficeLogin from './pages/login/login';

function PrivateRoute({ element }) {
  const loggedIn =true;

  return loggedIn ? element : <Navigate to="/login" />;
}
const TRACKING_ID = "G-XJ4QRDQMJV";
ReactGA.initialize(TRACKING_ID);
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname
});
function App() {
  

  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    setLoggedIn(true); // Update the loggedIn state
  }
  function PrivateRoute({ element }) {
    return loggedIn ? element : <Navigate to="/login" />;
  }
 


  return (
    <div>

        
       
      <Routes>
        <Route path='/login' element={<BackofficeLogin onLogin={handleLogin}/>} />
        <Route path="/choix" element={<Choix />} />
        <Route path="/merci" element={<Merci />} />
        <Route path="/" element={<Accueil />} />
        <Route path="/admin" element={<PrivateRoute element={<Admin />} />} />

        
        </Routes>
    </div>
  );
}

export default App;
