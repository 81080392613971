import React from 'react';

const Hello = () => {
  return (
    <div className='index'>
      <h1>L'operation commence le 01/03/2024  </h1>
    </div>
  );
};

export default Hello;
