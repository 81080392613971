import React from 'react';
import merci from './merci.module.css'
const Merci = () => {
    return (
        <div className={`${merci.bodymerci}`}>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"/> 
            <div className={`${merci.container}`}>
                <div className={`${merci.index}`}>
                    <img src='/choix/LOGOS.png'></img>
                </div>
                
                <div className={`${merci.msg}`} >
                    <p className={`${merci.g}`}>
                        MERCI !
                    </p>
                    <p className={`${merci.g2}`}>Pour votre participation</p>
                </div>
                <div className={`${merci.col}`}>
                    <p>Le Rustique vous souhaite <br></br>un bon moment de reconnexion à la nature !</p>
                </div>
                <div className={`${merci.fo}`}>
                    <div className={`${merci.fo1}`}><a href="https://www.quiveutdufromage.com/m-le-rustique" target="_blank"><p>Pour en savoir plus sur la marque <br></br>Le Rustique, ses engagement et <br></br>ses fromages, cliquez ici !</p></a></div>
                </div>
            </div>
            <div className={`${merci.containerM}`}>
                <div className={`${merci.indexM}`}>
                <img src='/choix/LOGO 2.png'></img>
                </div>
                
                <div className={`${merci.msgM}`} >
                    <p className={`${merci.gM}`}>
                        MERCI !
                    </p>
                    <p className={`${merci.g2M}`}>Pour votre participation</p>
                </div>
                <div className={`${merci.colM}`}>
                    <p>Le Rustique vous souhaite <br></br>un bon moment de reconnexion <br></br>à la nature !</p>
                </div>
                <div className={`${merci.foM}`}>
                 <div className={`${merci.fo1M}`}><a href="https://www.quiveutdufromage.com/m-le-rustique" target="_blank"><p>Pour en savoir plus sur la marque <br></br>Le Rustique, ses engagements et <br></br>ses fromages, cliquez ici !</p></a></div>
                </div>
            </div>
            <div className={`${merci.footer}`}>
                <a href="/modalites.pdf" target="_blank">Modalités</a>
                <a href="mailto:contact@lerustique-unepartdenature.fr">Contactez-nous</a>
                <a href="/ML2024.pdf" target="_blank">Mentions légales</a>
                <a href="/Politiquedec.pdf" target="_blank">Cookies</a>

            </div>
        </div>
    );
};

export default Merci;
