import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const BackofficeLogin = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    
    // Simulate authentication (replace with your actual authentication logic)
    if (username === 'mbressy@bogoplus.fr' && password === 'Bogo150915') {
onLogin();
      navigate('/admin');
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    
    <div>
      
      <h2>Login Page</h2>
      <label>
        Username:
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </label>
      <br />
      <label>
        Password:
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </label>
      <br />
      <button onClick={handleLogin}>Login</button>
     
    </div>
  );
};

export default BackofficeLogin;
