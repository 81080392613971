import React, { useEffect, useState } from 'react';
import accueil from './accueil.module.css';
import api from '../../apis/api';
import { useNavigate } from 'react-router-dom';
const Accueil = () => {
  const [code, setCode] = useState('');
  const [userExists, setUserExists] = useState(null);
  const navigate = useNavigate();
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleCheckUser = async () => {

    try {
      const result = await api.userCheck(code);

      if (result.userExists) {
        localStorage.setItem('userCode', code);
        navigate('/choix');

      } else {
        alert('Utilisateur non trouvé. Veuillez vérifier le code.');
      }
    } catch (error) {
      console.error('Error checking user:', error);
      alert('Une erreur s\'est produite lors de la vérification de l\'utilisateur.');
    }
  };


  return (
    <div className={`${accueil.bodyac}`}>
      
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      <script 
      async 
      src={`https://www.googletagmanager.com/gtag/js?id=G-XJ4QRDQMJV`}/>
      <div className={`${accueil.containerMobile}`}>

        <img className={`${accueil.logoMobile}`} src='/accueil/LOGOMobile.png'></img>
        <div className={`${accueil.indexMobile}`}>
          <div className={`${accueil.indexM}`}>
            <p>Bénéficiez d'une activité reconnexion<br></br> à la nature à choisir parmi<br></br> les partenaires de l'opération ! </p>
          </div>
          <div className={`${accueil.image}`}>
            <img className={`${accueil.logoMobile}`} src='/accueil/DOTA.png'></img>

          </div>

          <div className={`${accueil.codeM}`}>
            <p className={`${accueil.titreM}`}>Saisissez votre code*</p>
            <div className={`${accueil.cotM}`}>
              <input type='text' name="code" value={code} onChange={handleCodeChange}></input>
              <div>
                <button onClick={handleCheckUser}><span>OK</span></button></div></div>
            <p className={`${accueil.ob}`}>*champ obligatoire à remplir</p>

          </div>
          <div className={`${accueil.derM}`}>
            <p className={`${accueil.qsM}`}>Comment en bénéficier ?</p>
            <p><span>1</span>Saisissez votre code unique avant le 31 novembre 2024.</p>
            <p><span>2</span>Sur la carte de France, cliquez sur la région puis le partenaire de votre choix</p>
            <p><span>3</span>Renseignez le nom, prénom et adresse mail du bénéficiaire, puis validez</p>
            <p><span>4</span>Vous recevez par mail un pass nominatif valable chez le partenaire.</p>
          </div>
        </div>
      </div>
      <div className={`${accueil.container}`}>

        <div className={`${accueil.index}`}>
          <p>Bénéficiez d'une activité reconnexion<br></br> à la nature à choisir parmi<br></br> les partenaires de l'opération ! </p>
        </div>

        <div className={`${accueil.code}`}>
          <p className={`${accueil.titre}`}>Saisissez votre code*</p>
          <div className={`${accueil.cot}`}>
            <input type='text' name="code" value={code} onChange={handleCodeChange}></input>
            <div>
              <button onClick={handleCheckUser}><span>OK</span></button></div></div>
          <p className={`${accueil.ob}`}>*champ obligatoire à remplir</p>

        </div>
        <div className={`${accueil.der}`}>
          <p className={`${accueil.qs}`}>Comment en bénéficier ?</p>
          <p><span>1</span>Saisissez votre code unique avant le 31 novembre 2024.</p>
          <p><span>2</span>Sur la carte de France, cliquez sur la région puis le partenaire de votre choix</p>
          <p><span>3</span>Renseignez le nom, prénom et adresse mail du bénéficiaire, puis validez</p>
          <p><span>4</span>Vous recevez par mail un pass nominatif valable chez le partenaire.</p>
        </div>

      </div>
      <div className={`${accueil.footer}`}>
        <a href="/modalites.pdf" target="_blank">Modalités</a>
        <a href="mailto:contact@lerustique-unepartdenature.fr">Contactez-nous</a>
        <a href="/ML2024.pdf" target="_blank">Mentions légales</a>
        <a href="/Politiquedec.pdf" target="_blank">Cookies</a>

      </div>
      <div className={`${accueil.footerM}`}>
        <a href="/modalites.pdf" target="_blank">Modalités</a>
        <a href="mailto:contact@lerustique-unepartdenature.fr?subject=Rustique">Contactez-nous</a>
        <a href="/ML2024.pdf" target="_blank">Mentions légales</a>
        <a href="/Politiquedec.pdf" target="_blank">Cookies</a>

      </div>


    </div>
  );
};

export default Accueil;
