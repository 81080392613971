import styles from './admin.module.css'
import api from '../../apis/api';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const Admin = ({user}) => {
    const [users, setusers] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {

            const response = await api.getAllUsers();
            console.log(response)
            console.log('Data fetched successfully:', response);
            const data = await response;

            setusers(data);

        };

        fetchData();
    }, []);
  
    const validate = async (userId) => {
        try {
          // Assuming validateParticipation is a function in your API file
          await api.updateEtatById(userId);
          const response = await api.getAllUsers();
          setusers(response);
          console.log(`User with ID ${userId} validated successfully`);
        } catch (error) {
          console.error('Error validating user:', error);
        }
      };
      const refuser = async (userId) => {
        try {
          await api.updateEtatToFalseById(userId); // Appel de la nouvelle fonction pour mettre à jour l'état en "false"
          const response = await api.getAllUsers();
          setusers(response);
          console.log(`User with ID ${userId} refused successfully`);
        } catch (error) {
          console.error('Error refusing user:', error);
        }
      };
    
    //   const validate = async (userId) => {
    //     try {
    //       await validateParticipation(userId);
    //       const response = await api.getAllUsers();
    //       setusers(response)
    //       console.log(`User with ID ${userId} validated successfully`);
    //     } catch (error) {
    //       console.error('Error validating user:', error);
    //     }
    //   };
    return (

        <div>
            <div className={`${styles.sidemenu}`}>
                <div className={`${styles.brandname}`}>
                    <h1>Le Rustique</h1>
                </div>
                <ul>
                    <li><img src="/admin/tableau-de-bord.png" alt="" />&nbsp; <span>Dashboard</span> </li>

                </ul>
            </div>
            <div className={`${styles.container}`}>
                <div className={`${styles.header}`}>
                <img src='/choix/LOGOS.png'></img> 

                    <div className={`${styles.nav}`}>
                        
                        <div className={`${styles.user}`}>
                            <div className={`${styles.imgcase}`}>
                                <img src="/admin/homme.png" />
                                <h6></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.content}`}>
                    <div className={`${styles.cards}`}>
                        <div className={`${styles.card}`}>
                            <div className={`${styles.box}`}>
                                <h1>{users.length}</h1>
                                <h3>Participants</h3>
                            </div>
                            <div className={`${styles.iconcase}`}>
                                <img src="students.png" alt="" />
                            </div>
                        </div>



                    </div>
                    <div className={`${styles.contenu2}`}>
                        <div className={`${styles.recentpayments}`}>
                            <div className={`${styles.title}`}>
                                <h2>Les participations</h2>
                 
                            </div>

                            <table>
                                <tr>
                                    <th>ID</th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Tickets</th>
                                    <th>code</th>
                                    <th>actions</th>
                                </tr>
                                {users.map(user => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.nom}</td>
                                        <td>{user.prenom}</td>
                                        <td>{user.email}</td>
                                        <td style={{ color: user.valider === true ? 'green' : (user.valider === false ? 'red' : 'orange') }}>
  {user.valider === true ? 'validée' : (user.valider === false ? 'refusé' : 'en attente')}
</td>
                                        <td>
                                            <a href={`${user.lienTicket}`}
                                            >
                                                facture
                                            </a>
                                        </td>
                                        <td>{user.code}</td>
                                        <td><button onClick={() => validate(user.id)}>Valider</button><button onClick={() => refuser(user.id)}>refuser</button></td>

                                    </tr>
                                ))}

                            </table>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Admin;
