import axios from 'axios';

const BASE_URL = 'https://api.lerustique-unepartdenature.fr/api'; 

const api = axios.create({
  baseURL: BASE_URL,
  'Content-Type': 'multipart/form-data',

 
});

export const importUsers = async (excelfile) => {
  try {
    const response = await api.post('/import-exceluser', excelfile);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await api.get('/get-all-users');
    return response.data;
  } catch (error) {
    console.error('Error fetching participation:', error);
    throw error;
  }
};
export const addParticipation = async (code, partenaireId) => {
  try {
    const response = await api.post('/add-participation', { code, partenaireId });
    return response.data;
  } catch (error) {
    console.error('Error adding participation:', error);
    throw error;
  }
};

export const userCheck = async (code ) => {
  try {
    const response = await api.get(`/check-user/${code}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching participation:', error);
    throw error;
  }
};
export const getAllPartenaires = async () => {
  try {
    const response = await api.get('/get-all-partenaires');
    return response.data;
  } catch (error) {
    console.error('Error fetching all partenaires:', error);
    throw error;
  }
};

export const getPartenairesByRegion = async (region) => {
  try {
    const response = await api.get(`/get-partenaires-by-region/${region}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching partenaires by region:', error);
    throw error;
  }
};
export const updateEtatById = async (userId) => {
  try {
    const response = await api.put(`/update-etat/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error updating etat by ID:', error);
    throw error;
  }
};
export const updateEtatToFalseById = async (userId) => {
  try {
    const response = await api.put(`/update-etat-false/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error updating etat to false by ID:', error);
    throw error;
  }
};
export default {importUsers,getAllUsers,userCheck,getAllPartenaires,getPartenairesByRegion,addParticipation,updateEtatById,updateEtatToFalseById};
