import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-XJ4QRDQMJV";

// Initialize Google Analytics
ReactGA.initialize(TRACKING_ID);
// Send the initial pageview
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname
});
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);



root.render(
  <Router>
    <App />
  </Router>
);
